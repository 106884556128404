import { Tabs, rem } from "@mantine/core";

import classes from "./css/Demo.module.css";

export default function PreviewsPage() {
  return (
    <Tabs variant="unstyled" defaultValue="upcoming-anime" classNames={classes}>
      <Tabs.List>
        <Tabs.Tab value="upcoming-anime">Upcoming Anime</Tabs.Tab>
        <Tabs.Tab value="upcoming-manga">Upcoming Manga</Tabs.Tab>
        <Tabs.Tab value="trailers">Trailers</Tabs.Tab>
        <Tabs.Tab value="first-impressions">First Impressions</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="upcoming-anime">Upcoming Anime</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="upcoming-manga">Upcoming Manga</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="trailers">Trailers</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="first-impressions">First Impressions</Tabs.Panel>
    </Tabs>
  );
}
