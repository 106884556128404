import { Tabs, rem } from "@mantine/core";

import classes from "./css/Demo.module.css";

import FeaturesNews from "../components/home/FeaturedNews";

export default function HomePage() {
  return (
    <Tabs variant="unstyled" defaultValue="featured" classNames={classes}>
      <Tabs.List>
        <Tabs.Tab value="featured">Featured News</Tabs.Tab>
        <Tabs.Tab value="latest">Latest Articles</Tabs.Tab>
        <Tabs.Tab value="popular">Popular Articles</Tabs.Tab>
        <Tabs.Tab value="trending">Trending Topics</Tabs.Tab>
        <Tabs.Tab value="upcoming">Upcoming Events</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10} value="featured">
        <FeaturesNews />
      </Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10} value="latest">
        Latest Articles
      </Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10} value="popular">
        Popular Articles
      </Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10} value="trending">
        Trending Topics
      </Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10} value="upcoming">
        Upcoming Events
      </Tabs.Panel>
    </Tabs>
  );
}
