import { Tabs, rem } from "@mantine/core";

import classes from "./css/Demo.module.css";

export default function VideoPage() {
  return (
    <Tabs variant="unstyled" defaultValue="review-reaction" classNames={classes}>
      <Tabs.List>
        <Tabs.Tab value="review-reaction">Reviews & Reactions</Tabs.Tab>
        <Tabs.Tab value="interviews">Interviews</Tabs.Tab>
        <Tabs.Tab value="trailers">Trailers</Tabs.Tab>
        <Tabs.Tab value="convention">Convention Coverage</Tabs.Tab>
        <Tabs.Tab value="tutorials">Tutorials</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="review-reaction">Reviews & Reactions</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="interviews">Interviews</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="trailers">Trailers</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="convention">Convention Coverage</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="tutorials">Tutorials</Tabs.Panel>
    </Tabs>
  );
}
