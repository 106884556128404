import { Tabs, rem } from "@mantine/core";

import classes from "./css/Demo.module.css";

export default function FeaturesPage() {
  return (
    <Tabs variant="unstyled" defaultValue="editorials" classNames={classes}>
      <Tabs.List>
        <Tabs.Tab value="editorials">Editorials</Tabs.Tab>
        <Tabs.Tab value="interviews">Interviews</Tabs.Tab>
        <Tabs.Tab value="opinion">Opinion Pieces</Tabs.Tab>
        <Tabs.Tab value="top-lists">Top Lists</Tabs.Tab>
        <Tabs.Tab value="in-depth-analysis">In-Depth Analysis</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="editorials">Editorials</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="interviews">Interviews</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="opinion">Opinion Pieces</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="top-lists">Top Lists e.g., Top 10 Anime of the Year</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="in-depth-analysis">In-Depth Analysis</Tabs.Panel>
    </Tabs>
  );
}
