import { Tabs, rem } from "@mantine/core";

import classes from "./css/Demo.module.css";

export default function AboutPage() {
  return (
    <Tabs variant="unstyled" defaultValue="about-us" classNames={classes}>
      <Tabs.List>
        <Tabs.Tab value="about-us">About Us</Tabs.Tab>
        <Tabs.Tab value="staff-bios">Staff Bios</Tabs.Tab>
        <Tabs.Tab value="contact">Contact Information</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="about-us">About Us</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="staff-bios">Staff Bios</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="contact">Contact Information</Tabs.Panel>
    </Tabs>
  );
}
