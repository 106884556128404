import React, { useEffect, useState } from "react";

import { Text, Paper, Flex } from "@mantine/core";

// Define the News component
const FeaturesNews = () => {
  // Define state to store the fetched news data
  const [news, setNews] = useState([]);

  // Fetch news data from the API
  useEffect(() => {
    fetch("https://koiconnect-server-f3e8.onrender.com/home/featured-news")
      .then((response) => response.json()) // Parse the JSON response
      .then((data) => {
        // Sort the data by created_at in descending order (latest first)
        const sortedData = data.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        setNews(sortedData); // Store the sorted data in the state
      })
      .catch((error) => console.error("Error fetching news:", error));
  }, []); // Empty dependency array ensures this runs only once on component mount

  // Render the news data
  return (
    <Flex direction="column" gap="md">
      {news.map((item) => (
        <Paper  p="xl" style={{borderTop: "0.3px solid #212121", borderBottom: "0.3px solid #212121", borderRight: "0.3px solid #212121", borderLeft: "8px solid #212121"}} key={item.id}>
          <Text>{item.title}</Text>
        </Paper>
      ))}
    </Flex>
  );
};

export default FeaturesNews;
