import { Tabs, rem } from "@mantine/core";

import classes from "./css/Demo.module.css";

export default function NewsPage() {
  return (
    <Tabs variant="unstyled" defaultValue="industry" classNames={classes}>
      <Tabs.List>
        <Tabs.Tab value="industry">Industry News</Tabs.Tab>
        <Tabs.Tab value="announcements">Anime Announcements</Tabs.Tab>
        <Tabs.Tab value="releases">Manga Releases</Tabs.Tab>
        <Tabs.Tab value="events">Conventions & Events</Tabs.Tab>
        <Tabs.Tab value="streaming">Streaming Updates</Tabs.Tab>
        <Tabs.Tab value="studio">Studio Updates</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="industry">Industry News</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="announcements">Anime Announcements</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="releases">Manga Releases</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="events">Conventions & Events</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="streaming">Streaming Updates</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="studio">Studio Updates</Tabs.Panel>
    </Tabs>
  );
}
