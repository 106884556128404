// Import styles of packages that you've installed.
// All packages except `@mantine/hooks` require styles imports
import "@mantine/core/styles.css";

import { Flex, MantineProvider, NavLink, Text } from "@mantine/core";

import { AppShell, Burger, Group, Skeleton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from "./pages/HomePage";
import NewsPage from "./pages/NewsPage";
import ReviewsPage from "./pages/ReviewsPage";
import PreviewsPage from "./pages/PreviewsPage";
import FeaturesPage from "./pages/FeaturesPage";
import GuidePage from "./pages/GuidePage";
import VideoPage from "./pages/VideoPage";
import AboutPage from "./pages/AboutPage";

import {
  MdHomeMax,
  MdOutlineReviews,
  MdReviews,
  MdOutlineFeaturedPlayList,
} from "react-icons/md";
import { FaRegNewspaper } from "react-icons/fa";
import { GiAbstract051 } from "react-icons/gi";
import { PiVideoLight } from "react-icons/pi";
import { FcAbout } from "react-icons/fc";

import { IoIosArrowForward } from "react-icons/io";

import { useState } from "react";

export default function App() {
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure();

  const [active, setActive] = useState(0);

  return (
    <MantineProvider>
      <AppShell
        header={{ height: 90 }}
        navbar={{
          width: 150,
          breakpoint: "sm",
          collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
        }}
        padding="md"
      >
        <AppShell.Header>
          <Group h="100%" px="md">
            <Burger
              opened={mobileOpened}
              onClick={toggleMobile}
              hiddenFrom="sm"
              size="md"
              color="dark"
            />
            <Burger
              opened={desktopOpened}
              onClick={toggleDesktop}
              visibleFrom="sm"
              size="md"
              color="dark"
            />
            <Text
              style={{ fontFamily: "Shark Made In Japan, sans-serif" }}
              fz={60}
            >
              KoiCon
            </Text>
          </Group>
        </AppShell.Header>
        <AppShell.Navbar p="md">
          <NavLink
            href="/"
            leftSection={<MdHomeMax size="1rem" stroke={1.5} />}
            fz="h2"
            label="home"
          />
          <NavLink
            href="/news"
            leftSection={<FaRegNewspaper size="1rem" stroke={1.5} />}
            fz="h2"
            label="news"
          />
          <NavLink
            href="/reviews"
            leftSection={<MdReviews size="1rem" stroke={1.5} />}
            fz="h2"
            label="reviews"
          />
          <NavLink
            href="/previews"
            leftSection={<MdOutlineReviews size="1rem" stroke={1.5} />}
            fz="h2"
            label="previews"
          />
          <NavLink
            href="/features"
            leftSection={<MdOutlineFeaturedPlayList size="1rem" stroke={1.5} />}
            fz="h2"
            label="features"
          />
          <NavLink
            href="/guides"
            leftSection={<GiAbstract051 size="1rem" stroke={1.5} />}
            fz="h2"
            label="guides"
          />
          <NavLink
            href="/video"
            leftSection={<PiVideoLight size="1rem" stroke={1.5} />}
            fz="h2"
            label="videos"
          />
          <NavLink
            href="/about"
            leftSection={<FcAbout size="1rem" stroke={1.5} />}
            fz="h2"
            label="about us"
          />
        </AppShell.Navbar>
        <AppShell.Main>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/news" element={<NewsPage />} />
              <Route path="/reviews" element={<ReviewsPage />} />
              <Route path="/previews" element={<PreviewsPage />} />
              <Route path="/features" element={<FeaturesPage />} />
              <Route path="/guides" element={<GuidePage />} />
              <Route path="/video" element={<VideoPage />} />
              <Route path="/about" element={<AboutPage />} />
            </Routes>
          </BrowserRouter>
        </AppShell.Main>

        <AppShell.Footer p="md">
          <Flex align="center" justify="space-between">
            <Flex direction="column" justify="flex-start" align="flex-start">
              <Text
                style={{ fontFamily: "Shark Made In Japan, sans-serif" }}
                fz={20}
              >
                KoiCon
              </Text>
              <Text
                style={{ fontFamily: "'Almost Japanese Smooth', sans-serif" }}
                fz={10}
              >
                For The Love of Anime
              </Text>
            </Flex>
            <Text fz={13}>&copy; 2024 Koicon. All rights reserved.</Text>
          </Flex>
        </AppShell.Footer>
      </AppShell>
    </MantineProvider>
  );
}
