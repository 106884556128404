import { Tabs, rem } from "@mantine/core";

import classes from "./css/Demo.module.css";

export default function ReviewsPage() {
  return (
    <Tabs variant="unstyled" defaultValue="anime-reviews" classNames={classes}>
      <Tabs.List>
        <Tabs.Tab value="anime-reviews">Anime Reviews</Tabs.Tab>
        <Tabs.Tab value="manga-reviews">Manga Reviews</Tabs.Tab>
        <Tabs.Tab value="episode-reviews">Episode Reviews</Tabs.Tab>
        <Tabs.Tab value="movie-reviews">Movie Reviews</Tabs.Tab>
        <Tabs.Tab value="rating-system">Rating System Explanation</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="anime-reviews">Anime Reviews</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="manga-reviews">Manga Reviews</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="episode-reviews">Episode Reviews</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="movie-reviews">Movie Reviews</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="rating-system">Rating System Explanation</Tabs.Panel>
    </Tabs>
  );
}
