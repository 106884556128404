import { Tabs, rem } from "@mantine/core";

import classes from "./css/Demo.module.css";

export default function GuidePage() {
  return (
    <Tabs variant="unstyled" defaultValue="watch" classNames={classes}>
      <Tabs.List>
        <Tabs.Tab value="watch">Watch Guides</Tabs.Tab>
        <Tabs.Tab value="reading">Reading Guides</Tabs.Tab>
        <Tabs.Tab value="character">Character Guides</Tabs.Tab>
        <Tabs.Tab value="fanchise">Franchise Overviews</Tabs.Tab>
        <Tabs.Tab value="mechs">Merchandise Guides</Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="watch">Watch Guides</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="reading">Reading Guides</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="character">Character Guides</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="fanchise">Franchise Overviews</Tabs.Panel>
      <Tabs.Panel bd="0.5px solid grey" p={10} mt={10}  value="mechs">Merchandise Guides</Tabs.Panel>
    </Tabs>
  );
}
